import { effect, inject, Injectable, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export default class MbpTitleStrategy extends TitleStrategy {
    private title = inject(Title);
    routeTitle = signal<string | null>(null);

    constructor() {
        super();
        effect(() => {
            const title = this.routeTitle() ?? 'Hasseltbonnen';
            this.title.setTitle(title);
            environment.mbpClient.ui.setTitle(title);
        });
    }

    override updateTitle(snapshot: RouterStateSnapshot) {
        this.routeTitle.set(this.buildTitle(snapshot) ?? null);
    }
}
